body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0.4rem; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #a0a0a09a; /* Set the color of the thumb */
  border-radius: 4px; 
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the track */
}
