// Glossary.scss
@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import 'bulma/sass/form/_all';
@import "bulma/sass/components/tabs";

@import "../../assets/bulma-overrides/bulmaOverrides";

$primary-color: #00c853;
$border-color: #e0e0e0;
$text-color: #000000;

.glossary-tp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  font-family: $bricolage-font !important;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1500px;
  height: auto;
  min-height: 100vh;
  margin: 0px auto;
  overflow: visible;
  width: 100%;


  @media (max-width: 480px) {
    height: auto;
    width: 100%;
  }

  @media (max-width: 769px) {
    height: auto;
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 100vw;
    max-width: 100%;
  }

  h1 {
    font-family: $bricolage-font !important;
    font-size: 2rem !important;
    font-weight: 400;
    margin-bottom: 20px;
  }

  h2 {
    color: #3f77f8;
    font-family: $bricolage-font !important;
    font-size: 1.5rem !important;
    line-height: 3rem !important;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .menu-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: black;
    border-radius: 5px;
    padding: 30px 70px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
    width: fit-content;
    border: 1px solid black;

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

      svg #hover {
        fill: #8fbffa !important;
      }
    }

    .menu-btn-text {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  .horizontal-rule {
    margin-top: 50px;
    width: 95.7%;
    height: 2px;
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgb(219, 219, 219);
    border-left: none;
    border-image: initial;
  }

  .seo-project-abun-table {
    width: 100%;

    td {
      max-width: 400px;
      font-size: 0.9em;

      &:hover {
        cursor: pointer;
        text-decoration: none !important;
        color: #3f77f8;
      }
    }
  }


  .seo-project-header {
    position: relative;
    width: 100%;
    height: 50px;
  }
}

.glossary-card {
  background-color: #ffffff;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 40px;
  max-width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100vw;
  height: 100vh;

  @media (max-width:769px) {
    height: fit-content;
  }

  .back-btn {
    position: absolute;
    // left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;

    svg {
      @media (max-width: 480px) {
        width: 15px;
        height: 12px;
      }
    }
  }

  .glossary-title {
    font-size: 24px;
    font-weight: 400;
    color: $text-color;
    margin-bottom: 16px;
    font-family: "Bricolage Grotesque", normal !important;
  }

  .glossary-content {
    padding-top: 150px;

    .glossary-subtitle {
      color: #3F77F8;
      margin-bottom: 8px;
      font-size: 32px !important;
      font-weight: 900;
    }

    .glossary-description {
      font-size: 16px;
      color: #9f9f9f;
      margin-bottom: 24px;
    }

    .glossary-input-container {
      margin-bottom: 24px;

      .glossary-input {
        width: 100%;
        padding: 10px;
        border: 1px solid $border-color;
        border-radius: 4px;
        font-size: 14px;
        color: $text-color;
        max-width: 300px;

        &:focus {
          outline: none;
          border-color: #3F77F8;
          box-shadow: 0 0 4px #000000;
        }

        &:hover {
          color: #000000 !important;
        }
      }
    }

    .glossary-button {
      background-color: $primary-color;
      color: #ffffff;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }

  .article-title-header:hover {
    color: #3F77F8;
  }

  .loading-row {
    text-align: center;
    vertical-align: middle;
  }

  .loading-text {
    display: inline-block;
    margin: 0;
  }
}

.glossary-topic-card {
  margin: 40px auto;
  margin-top: 10px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
  position: relative;

  @media(max-width: 769px) {
    width: 100%;
  }

  @media(min-width: 1024px) {
    width: 100vw;
    max-width: 100%;
  }

  .back-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;

    svg {
      @media (max-width: 480px) {
        width: 15px;
        height: 12px;
      }
    }
  }

  .custom_button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #3F77F8;
    cursor: pointer;
    margin: 0;
    transition: background-color 0.2s ease, color 0.2s ease;
    margin-left: 10px;
  }

  .glossary-content-term {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 16px;
    font-family: "Bricolage Grotesque", normal !important;
    cursor: pointer;
  }

  .count-glossary {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .create-content-btn {
    margin: auto;
    border-radius: 8px !important;
  }

  .table-container {
    // margin-top: 3rem;
    width: 96.5%;
    min-height: 20vh;


    .abun-table-no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30vh;
      font-size: 1.5rem;
      color: #9f9f9f;
    }

    .abun-table-responsive {
      overflow-x: auto;

      h1.abun-table-title {
        font-family: Bricolage Grotesque;
        font-size: 1.5rem !important;
        font-weight: 400;
        line-height: 3rem !important;
        text-align: left;
        color: #3F77F8;
      }

      tbody {

        td:first-child {
          width: 50%;
          text-align: start !important;
        }
  
        td {
          text-align: center;
        }
      }

      thead {

        // first th
        th:first-child div {
          font-family: $inter-font;
          margin: 0px !important;
        }

        th div {
          font-family: $inter-font;
          margin: auto;
        }
      }

    }


    &.has-checkbox-header{
      .abun-table-responsive {
        overflow-x: auto;
  
        h1.abun-table-title {
          font-family: Bricolage Grotesque;
          font-size: 1.5rem !important;
          font-weight: 400;
          line-height: 3rem !important;
          text-align: left;
          color: #3F77F8;
        }
  
        tbody {
          
          td:first-child {
            width: 10px; /* Minimal width for checkbox */
            text-align: left;
          }
    
          td:nth-child(2) {
            width: 50%; /* Topic column occupies 50% */
            text-align: start !important;
          }
  
          td {
            text-align: center;
          }
        }
  
        thead {
  
          // first th
          th:first-child div {
            font-family: $inter-font;
            margin: 0px !important;
          }
          th:nth-child(2) div {
            margin: 0px !important;
          }
  
          th div {
            font-family: $inter-font;
            margin: auto;
          }
        }
  
      }
    }
  }

  /* Table styling */
  .article-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-bottom: 2px solid hsl(0, 0%, 86%);
    font-family: "Archivo", sans-serif;
  }

  .article-table th,
  .article-table td {
    padding: 15px;
  }

  .article-table th:first-child:not([align]) {
    text-align: left;
  }

  .article-table th {
    color: #3F77F8;
    font-weight: bold;
    font-family: "Bricolage Grotesque", normal;
    font-size: 1.3em;
    padding-bottom: 0;
    border-bottom: 2px solid hsl(0, 0%, 86%);
  }

  .article-table td {
    border-bottom: 1px solid hsl(0, 0%, 86%);
    padding-bottom: 8px;
  }

  .article-table td:first-child {
    color: #000000;
    font-size: 1em;
    text-align: left;
    max-width: 400px;
    font-family: "Bricolage Grotesque", normal;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .article-table tr:hover {
    background-color: #f9f9f9;
  }

  .delete-btn {
    background: none;
    border: none;
    font-size: 16px;
    color: red;
    cursor: pointer;
  }
}