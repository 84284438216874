@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/components/card";

@import "../../assets/bulma-overrides/bulmaOverrides";

.reset-password-container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  // background-color: $primary;
  background-image: linear-gradient(to bottom, #B5E9FF, #FFFFFF);
}

.reset-password-container .reset-password-card {
  border-radius: 16px;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.1607843137);
  width: 600px;
  max-width: 90%;

  .card-content {
    padding: 2rem;
  }
}

.reset-password-header{
  margin-top: 10em;
}

@media  only screen and (max-width:550px){
  .reset-password-header{
    margin-top: 7em;
  }
}


.reset-password-input-border-danger {
  border-color: $danger;
}

.reset-password-input-border-success {
  border-color: $success;
}



.success-password-container {
  // justify-content: space-between;
  height: 100vh;
  font-family: $primary-font;
  background-image: linear-gradient(to bottom, #B5E9FF, #FFFFFF);

  .check-icon {
    height: 4rem;
    margin-inline: auto;
    font-size: 4rem;
    margin-bottom: 3rem;
  }

  .card-contain{
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 16px !important;
    position: absolute;
    width: 35%;
    top: 33%;
    right: 33%;
    padding: 1.5rem;
    box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1), 0 0px 0 1px rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include until(1300px) {
      width: 60%;
      right: 20%;
   }

   @include until(769px) {
      width: 70%;
      right: 15%;
   }
  }
  h2{
    font-size: 1.6rem;
  }
  p{
    font-size: 1rem;
  }
 

 }


 @media only screen and (max-width: 600px) {
  .success-password-container .card {
   position: static;
   padding-block: 1rem;
   box-shadow: 0 8px 17px rgba(0, 0, 0, 0.1607843137);
   border-radius: 1rem;
   right: 32%;
   width: 90%;
   margin-left: auto;
   margin-right: auto;
   margin-top: 200px;
  }
}
