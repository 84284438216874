@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

// bulma overrides
$card-content-padding: 4rem;
$input-shadow: unset;
$input-border-color: $grey-dark;
$input-color: $grey-darker;
$input-placeholder-color: $grey-darker;

@import "bulma/sass/utilities/all";

// Full background styling for the modal-like effect
.ca-signup-container::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: url('../../assets/images/Article_Gen/article_gen.png');
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(0.5);
}

.ca-signup-container {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .heading-text {
        font-family: $secondary-font;
        font-size: 2rem;
        line-height: 1.2em;
        font-weight: 700;
        color: $black;
        text-align: center;

        @include until($tablet) {
            font-size: 2rem;
        }

        @media screen and (max-width: 1560px) {
            font-size: 2.5rem;
        }
    }

    .epilogue {
        font-family: $epilogue-font;
        margin-bottom: 30px;
    }

    .subtitle-text {
        font-size: 1.5rem;
        color: $black;
        text-align: center;

        @media screen and (max-width: 1560px) {
            font-size: 1.25rem;
        }
    }

    .signup-card {
        width: 40%;
        border-radius: 16px;
        box-shadow: 0 8px 17px #00000029;

        @media screen and (max-width:1560px) {
            width: 90%;
        }

        .card-content {
            padding: 4rem;

            @include until($tablet) {
                padding: 2rem;
            }

            .content .signup-btn-border {
                font-family: "Inter", Sans-serif;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                padding: 15px 50px 15px 60px;
                background-color: #ffffff !important;
                color: #000000 !important;
                border: 1px solid #000000;
                border-radius: 09px !important;
                transition: 0.2s;
            }

            .content .signup-btn-border:hover {
                transform: translate(-0.25rem, -0.25rem);
                background: #fac44b !important;
                box-shadow: 0.25rem 0.25rem #000000;
            }

            .content .signup-In-btn-border{
                font-family: "Inter", Sans-serif;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                padding: 15px 50px 15px 60px;
                transform: translate(-0.25rem, -0.25rem);
                background: #fac44b !important;
                box-shadow: 0.25rem 0.25rem #000000;
                color: #000000 !important;
                border: 1px solid #000000;
                border-radius: 09px !important;
                transition: 0.2s;
            }

            .content .divider-wrapper {
                display: flex;
                flex-direction: row;
                text-transform: uppercase;
                border: none;
                font-weight: 600;
                margin: 0;
                align-items: center;
                justify-content: center;
                vertical-align: baseline;

                .divider {
                    text-align: center;
                    flex: .2 0 auto;
                    margin: 0;
                }
            }

            .content .connect-account {
                display: flex;
                width: 100%;
                justify-content: center;

                .google-option {
                    font-weight: 600;
                    background-color: #ffffff;
                    border: 1px solid black;
                    width: 300px;
                    border-radius: 9px;
                    height: 40px;
                    padding: 7px;
                }

                .google-option:hover {
                    cursor: pointer;
                    box-shadow: inset 0 0 0 150px #0000001a
                }

            }
        }
    }
}



@media only screen and (max-width: 600px) {
    .signup-container {
        padding-top: 120px;
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .signup-btn-border {
        padding: 15px 20px 15px 20px !important;
    }
}


.show-error-message-below {
    position: relative !important;

    &::after {
        content: attr(data-err-msg);
        position: absolute;
        bottom: -25px;
        left: 0;
        // color: $danger;
        font-size: .85rem;
    }
}

.show-error-message-above {
    position: relative !important;

    &::after {
        content: attr(data-err-msg);
        position: absolute;
        bottom: 3.5rem;
        left: 0;
        color: #e12047;
        font-size: 1rem;
        width: 100%;
        text-align: center;
    }

}