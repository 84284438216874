@import "../../assets/fonts/customFonts";

.main-nav{
    padding-top: 30px;

    .nav-container{
        width: 1100px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ffffff;
        padding: 15px;
        padding-left: 25px;
        padding-right: 20px;
        border-radius: 200px;
        border: 2px solid #000000;
        box-shadow: 5px 10px 1px 0px #000000;


        .logo{
            display: flex;
            align-items: flex-start;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .main-nav{
        padding-left: 20px;
        padding-right: 20px;

        .nav-container{
            padding: 10px 20px;


            .logo{
                width: 120px;
            }
        }
    }
}


// navbar do not overflow
@media only screen and (max-width:1118px) and (min-width:975px) {
    .nav-container {
        width: auto !important;
    }
}