@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

$input-placeholder-color: $grey-darker;

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/form/all";
@import "bulma/sass/helpers/typography";

@import "../../assets/bulma-overrides/bulmaOverrides";
@import "../../assets/custom-global-styles/customGlobablStyles";

.profile-email-field {
  position: relative;

  img {
    position: absolute;
    top: 9px;
    right: 9px;
  }
}
