@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import 'bulma/sass/form/_all';
@import "bulma/sass/components/tabs";

@import "../../assets/bulma-overrides/bulmaOverrides";

.tp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
    font-family: $bricolage-font !important;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1500px;
    height: auto;
    min-height: 100vh;
    margin: 0px auto;
    overflow: visible;
    width: 100%;


    @media (max-width: 480px) {
        height: auto;
        width: 100%;
    }

    @media (max-width: 769px) {
        height: auto;
        width: 100%;
    }

    @media (min-width: 1024px) {
        width: 100vw;
        max-width: 100%;
    }

    h1 {
        font-family: $bricolage-font !important;
        font-size: 2rem !important;
        font-weight: 400;
        margin-bottom: 20px;
    }

    h2 {
        color: #3f77f8;
        font-family: $bricolage-font !important;
        font-size: 1.5rem !important;
        line-height: 3rem !important;
        font-weight: 800;
        margin-bottom: 10px;
    }

    .menu-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        color: black;
        border-radius: 5px;
        padding: 30px 70px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-top: 20px;
        width: fit-content;
        border: 1px solid black;

        &:hover {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

            svg #hover {
                fill: #8fbffa !important;
            }
        }

        .menu-btn-text {
            font-size: 16px;
            margin-left: 10px;
        }
    }

    .horizontal-rule {
        margin-top: 50px;
        width: 95.7%;
        height: 2px;
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgb(219, 219, 219);
        border-left: none;
        border-image: initial;
    }

    .seo-project-abun-table {
        width: 100%;

        td {
            max-width: 400px;
            font-size: 0.9em;

            &:hover {
                cursor: pointer;
                text-decoration: none !important;
                color: #3f77f8;
            }
        }
    }


    .seo-project-header {
        position: relative;
        width: 100%;
        height: 50px;
    }
}