/* Header title */
.generator-header {
  font-family: "Bricolage Grotesque", normal;
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  margin-top: 30px;

  @media(max-width:769px) {
    margin-top: 100px;
  }
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ca-input {
  padding: 10px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #b0b0b0;
  border-radius: 10px;
  box-sizing: border-box;
  width: 340px;
  margin-bottom: 10px;
  text-align: center;
}

.submit-button {
  background-color: #007bff;
  /* Button background color */
  border: none;
  color: white;
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
  margin-left: 2px;
  margin-bottom: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button:hover {
  background-color: #0056b3;
  /* Darker shade on hover */
}

/* Button group styling */
.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 25px;
  background-color: white;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.tab-button:hover {
  background-color: #0056b3;
  color: white;
}

.ca-suggestions {
  background-color: #fff;
  padding: 5px;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid transparent;
  transition: border 0.3s ease;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;

}

.ca-suggestion-button {
  font-family: Inter;
  background-color: transparent;
  padding: 10px 20px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 10px;
}

.ca-suggestion-button:focus {
  outline: none;
}

.ca-suggestion-button:hover {
  background-color: rgba(219, 240, 255, 1);
  color: rgb(0, 150, 255);
}

.ca-suggestion-button.active {
  background-color: rgba(219, 240, 255, 1);
  color: rgb(0, 150, 255);
}



.circle-question {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: #fff;
  color: #333;
  text-align: center;
  font-weight: bold;
  margin-left: 8px;
  font-size: 12px;
  border: 2px solid #ccc;
}

#style_button {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}


/* Container card styling */
.generator-card {
  margin: 40px auto;
  margin-top: 10px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;

  @media(max-width:480px) {}

  @media(min-width:1024px) {
    width: 60vw;
    max-width: 100%;
  }
}

/* Table styling */
.article-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-bottom: 2px solid hsl(0, 0%, 86%);
  font-family: "Archivo", sans-serif;
}

.article-table th,
.article-table td {
  padding: 15px;
  // border-bottom: 1px solid #ddd;
}

.article-table th:first-child:not([align]) {
  text-align: left;
}


.article-table th {
  color: #3F77F8;
  font-weight: bold;
  font-family: "Bricolage Grotesque", normal;
  font-size: 1.3em;
  padding-bottom: 0;
  border-bottom: 2px solid hsl(0, 0%, 86%);
}

.article-table td {
  border-bottom: 1px solid hsl(0, 0%, 86%);
  padding-bottom: 8px;
}

.article-table td:first-child {
  color: #000000;
  font-size: 1em;
  text-align: left;
  max-width: 400px;
  border: '1px solid #ccc';
  font-family: "Bricolage Grotesque", normal;


  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.article-table tr:hover {
  background-color: #f9f9f9;
}

/* Create Article button styling */
.action-button {
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 12px;
  transition: background-color 0.2s ease;
}

.action-button:hover {
  background-color: #0056b3;
  color: white;
}