@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/elements/form";

.ellipsis {
    margin-top: 0.5rem;
    margin-left: 0.3rem;
}

.ellipsis:hover {
    cursor: pointer;
}

.ellipsis-dropdown-content {
    width: 200px;
    position: absolute;
    margin-top: 45px;
    margin-right: -85px;
    background-color: #f9f9f9;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.ellipsis-dropdown {
    text-align: center;
    list-style-type: none;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.ellipsis-dropdown:hover {
    cursor: pointer;
    background-color: #d8d4d4;
}