@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/components/modal";

@import "../../assets/bulma-overrides/bulmaOverrides";

.abun-modal-card {
  position: relative;

  .abun-modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

.wide-modal {
  width: max-content;

  .card {
    background-color: inherit;

    img {
      width: 2rem !important;
    }
  }
} 

