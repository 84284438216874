// ----------------------- BASE HTML & BODY -----------------------
.authbase-container {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, #B5E9FF, #FFFFFF);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}


// ----------------------- MAIN STYLES -----------------------