@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";

// ----------------------- BASE HTML & BODY -----------------------
.base-html {
  margin: 0;
  padding: 0;
}

.base-html * {
  font-family: $bricolage-font; //Before it was Nunito ($primary-font)
}

.base-body {
  margin: 0;
  padding: 0;
}

// ----------------------- MAIN STYLES -----------------------

$main-container-padding: 1.3rem;
$main-container-padding-sides-extra: 2rem;

.main-container {
  width: 100%;
  min-height: 100vh; // temporary
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f6fc;

  // 18rem is for sidebar placement. Update this if updating sidebar
  padding-top: $main-container-padding;
  padding-bottom: $main-container-padding;
  padding-left: $main-container-padding + 16rem + $main-container-padding-sides-extra;
  padding-right: $main-container-padding - 1.8rem + $main-container-padding-sides-extra;

  box-sizing: border-box;

  &.expanded {
    padding-left: $main-container-padding + 5.5rem + $main-container-padding-sides-extra;

    @include until(1100px) {
      padding-left: $main-container-padding + 3.5rem + $main-container-padding-sides-extra;
      padding-right: 2rem;
    }
  }

  @include until(1100px) {
    padding-left: $main-container-padding + 14rem + $main-container-padding-sides-extra;
    padding-right: 2rem;
  }

  @include until($tablet) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 6rem;
  }
}

#root .notification.position-bottom {
  // show at the center of the screen
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  padding: 1rem;

  button {
    top: 0 !important;
    right: 0 !important;
  }
}