@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import "bulma/sass/elements/form";
@import "bulma/sass/components/tabs";

@import "../../assets/bulma-overrides/bulmaOverrides";

.comp-research-kw-page-title {
  font-size: 1.75rem;
  font-family: $secondary-font;
}

.comp-research-kw-card {
  width: 100%;
}

#CompetitorResearchKeywordsTable {
  .abun-table-dropdown-menu-container {
    top: 7.75rem;
  }

  .abun-table-filter-buttons {
    display: none !important;
  }
}
