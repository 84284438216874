@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";

@import "../../assets/bulma-overrides/bulmaOverrides";

// ----------------------- BASE HTML & BODY -----------------------

//.connectwebsite-html {
//  margin: 0;
//  padding: 0;
//}
//
//.connectwebsite-html * {
//  font-family: $primary-font;
//}
//
//.connectwebsite-body {
//  margin: 0;
//  padding: 0;
//}

// ----------------------- MAIN STYLES -----------------------

.connectwebsite-logo {
  width: 8rem;
  height: auto;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.connectwebsite-title {
  font: bold 1.8rem $secondary-font;
  color: $primary;
  text-align: center;

  &.black {
    color: $black;
  }

  &.normal-font {
    font-weight: normal;
  }
}

.connectwebsite-domain-form {
  margin-top: 2rem;
  width: 35%;

  @include until(700px) {
    width: 80%;
  }

  // ----------------------- Add website domain -----------------------
  
  div.suggestions-container {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-rows: max-content;
    margin: auto;
    grid-gap: 0px;
    position: absolute;
    top: 61%;
    left: 32.5%;
    width: 35%;
    @include until(700px) {
      width: 80%;
      left: 25%;
    }
    background: #fdfdfd;
    border-radius: 21px;
    text-align: left;
    z-index: 1;
    transition: all 0.05s ease-in-out;
  
    .suggestion-item {
      padding: 0.75rem 0.5rem;
      cursor: pointer;
      border-radius: 3px;
      background-color: white;
      border: 1px solid #e6e6e66e;
      transition: all 0.25s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      margin: 0;
      max-width: 100%;

      @include until(700px) {
        width: 90%;
      }
  
      &:hover {
        background-color: $grey-lighter;
      }
  
      img {
        width: 30px;
        height: auto;
        margin-right: 0.5rem;
        border-radius: 18%;
      }
  
      span {
        font-size: 1rem;
        font-weight: 400;
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      div.suggestion-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-right: 0.5rem;
        color: $grey-darker;
      }

      span.suggestion-domain {
        font-size: 0.9rem;
        font-weight: normal;
        color: #3F77F8;
      }
    }
  }
}

.connectwebsite-keywords-form {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 1rem;
  margin-top: 2rem;
  width: 35%;

  @include until(700px) {
    width: 80%;
  }
}

.connect-website-competitors-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.2em;
  margin-top: 4em;

  @include until(1027px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include until(681px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .blacklist-card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .card-content {
      padding: 1rem 1.5rem;
    }

    .blacklist-comp-logo {
      width: 32px;
      height: auto;
      border-radius: 100%;
    }
  }
}

.return-to-dahshboard {
  margin-top: 2em;
  margin-bottom: 5em;
  color: $grey-darker;
  text-decoration: underline;
  font-size: .9rem;
}

.input-min-counter-label {
  display: none;
  color: $danger;
  font-size: 0.85em;
  font-weight: lighter;

  &.show {
    display: block;
  }
}
