@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

// bulma overrides
$card-content-padding: 4rem;
$input-shadow: unset;
$input-border-color: $grey-dark;
$input-color: $grey-darker;
$input-placeholder-color: $grey-darker;

@import "bulma/sass/utilities/all";
@import "bulma/sass/helpers/all";
@import "bulma/sass/elements/button";
@import "bulma/sass/components/card";
@import "bulma/sass/form/all";


.login-wrapper{
  align-content: center;
  padding: 20px;
  padding-top: 100px;
}

.divider{
  margin-left: 10px;
  margin-right: 10px;
}


.login-container {
  
  .heading-text {
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 3rem;
    color: $white;
    text-align: center;
    line-height: 1.2em;
  }

  .subtitle-text {
    font-size: 1.5rem;
    color: $white;
    text-align: center;
  }

  .login-card {
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 8px 17px #00000029;

    .card-content {
      padding: 4rem;

      @include until($tablet) {
        padding: 2rem;
      }

      .content .login-btn-border {
        font-family: $inter-font;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 15px 50px 15px 60px;
        background-color: #ffffff !important;
        color: #000000 !important;
        border: 1px solid #000000;
        border-radius: 09px !important;
        transition: 0.2s;

        span {
          color: #000000;
        }
      }

      .content .login-btn-border:hover {
        transform: translate(-0.25rem, -0.25rem);
        background: #fac44b !important;
        box-shadow: 0.25rem 0.25rem #000000;
      }
      .content .logging-In-btn{
        font-family: $inter-font;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 15px 50px 15px 60px;
        color: #000000 !important;
        border: 1px solid #000000;
        border-radius: 09px !important;
        transition: 0.2s;
        transform: translate(-0.25rem, -0.25rem);
        background: #fac44b !important;
        box-shadow: 0.25rem 0.25rem #000000;

      }
    }
  }

  .epilogue {
    font-family: $epilogue-font;
  }

  .bricolage {
    font-family: $bricolage-font;
  }

  @media screen and (max-height: 900px) {
    zoom: 0.9;
    .login-container {
      align-items: center;
      bottom: 0;
      display: flex;
      flex-direction: column;
      gap: .5rem;
      height: 33.75rem;
      /* position: fixed; */
      /* right: 32.5%; */
      /* top: 18%; */
      width: 80%!important;
      margin-left: auto!important;
      margin-right: auto!important;
  }
  }

}

.eye-icon{
  position: absolute!important;
  top: 10px!important;
  bottom: 0!important;
  margin: auto -30px!important;
}

.error-msg {
  font-size: 1rem;
}


.forgot-container{
  width: 650px!important;
  padding-top: 120px;
}

@media only screen and (max-width: 600px) {
  .login-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
 
  .login-wrapper{
    align-content: center;
    height: 100vh;
    padding: 0px;
    padding-top: 160px;
  }

.divider{
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0px;
  border-top: 1px solid #000000;
  padding: 10px;
  height: 1px;
  display: block;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: -10px;
}

.user-login{
  display: flex;
  flex-direction: column;
}

.content .login-btn-border {
  padding: 15px 40px 15px 40px!important;
}


.forgot-container{
  width: 90%!important;
  margin-left: auto;
  margin-right: auto;
  padding-top: 130px;
}

#send{
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100%;
}


}


