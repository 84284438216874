@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import 'bulma/sass/form/_all';
@import "bulma/sass/components/tabs";


/* Main container */
.ps-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align children to the left */
    padding: 20px 50px;
    font-family: $bricolage-font !important;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1500px;
    height: auto;
    /* Allow the container height to expand based on content */
    min-height: 100vh;
    margin: 0px auto;
    overflow: visible;
    width: 100%;

    .seo-project-header {
        position: relative;
        width: 100%;
        height: 50px;

        .seo-back {
            position: relative;
            width: 1.5938rem;
            height: 1.375rem;
            cursor: pointer;
            margin-left: -30px;
        }
    }

    .ps-header {
        align-self: center;
        font-family: $bricolage-font !important;
        font-size: 2rem !important;
        font-weight: 400;
        margin-bottom: 20px;

    }

    .seo-form-container {
        align-self: stretch;
        /* Stretch to the parent's width if needed */
        margin-left: 20px;
        margin-right: 50px;
        background: #ffffff;
        padding: 20px;
        border-radius: 8px;
        // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

        .seo-form {
            margin-left: 47px;

            @media (max-width: 450px) {
                margin-left: 0px !important;
            }

        }

        @media (max-width: 769px) {
            margin-left: 10px;
            /* Adjust for smaller screens */
            margin-right: 20px;
        }
    }

    h2 {
        font-family: "Bricolage Grotesque", normal !important;
        font-size: 1.5rem !important;
        font-weight: 400;
        color: #3f77f8;
        padding-bottom: 10px;
        align-self: flex-start;
        text-align: left;
        margin-left: 47px;

        @media (max-width: 450px) {
            margin-left: 0px !important;
        }
    }

    .form-group {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .seo-text {
            display: block;
            font-weight: bold;
            font-size: 14px;
        }
        .form-group-input-field{
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;
            margin-left: 1.5em;

        }

        .add-btn {
            cursor: pointer;
            background-color: #ffffff;
            color: #3f77f8;
            align-self: self-start;
            border: #ffffff;
        }

        .add-btn:disabled {
            cursor: not-allowed;
            color: #d3d3d3;
            opacity: .5;
            pointer-events: none;
        }

        .remove-btn {
            margin-left: 10px;
            width: 1.4rem;
            background-color: transparent;
            border: none;
            color: deeppink;
            cursor: pointer;
            background-color: #FCE9E9;
            border-radius: 49%;
            height: 1.4rem;
            padding-bottom: 2px;
            font-size: 15px;
        }


        .example-input-container {
            display: flex;
            align-items: center;
            justify-content: center;

            /* Example input field style */
            .example {
                width: 90%;
                /* Same width as the main input */
                padding: 10px;
                font-size: 14px;
                border: 1px solid #ced4da;
                border-radius: 4px;
                margin-bottom: 4px;
            }

            .errormessage {
                display: flex;
                justify-content: center;
            }
        }

    }

    .submit-btn {
        // width: 20%;
        width: auto;
        border: none;
        background: #2bc482;
        color: #ffffff;
        padding: 10px;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
    }

    .submit-btn:disabled {
        border: none;
        cursor: not-allowed;
        background: #96E6C4;
        color: white;
        border: 1px solid #d3d3d3;
    }

    .submit-btn:disabled:hover {
        cursor: not-allowed;
        background: #96E6C4;
        color: white;
        border: 1px solid #d3d3d3;
    }

    .submit-btn:hover {
        background: #2bc482;
        color: #ffffff;
    }

}