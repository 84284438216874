@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import 'bulma/sass/form/_all';
@import "bulma/sass/components/tabs";


/* Container for the page */
.ca-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1500px;
    height: 100vh;
    margin: 0px auto;
    // width:auto;

    @media(max-width:480px) {
        height: auto;
        width:100%;
    }
    @media(max-width:769px){
        height:auto;
        width:100%;
    }

    @media(min-width:1024px) {
        width: 100vw;
        max-width: 100%;
    }

    h2 {
        font-family: $bricolage-font !important;
        font-size: 2rem !important;
        font-weight: 400;
    }
}


/* Header section */
.ca-header {
    margin-bottom: 20px;
}

.ca-checkbox-row {
    display: flex;
    flex-wrap: nowrap; // Prevent wrapping
    justify-content: space-between; // Space out columns evenly
    margin: 0; // Remove margin on the container
    margin-bottom: 20px;

    .ca-checkbox-group {
        &.first-column {
            flex: 0 0 25%; // First column takes 25%
        }

        &.other-columns {
            flex: 0 0 37.5%; // Each of the other columns takes 37.5%
        }

        display: flex;
        flex-direction: column; // Stack items vertically
        align-items: flex-start; // Align items to the start of the column

        .ca-checkbox-item {
            display: flex;
            align-items: center; // Center items vertically
            padding: 10px; // Padding for touch-friendly design
            margin-bottom: 0px; // Space between items

            .elementor-icon-list-icon {
                margin-right: 10px; // Space between icon and text
                display: flex;
                align-items: center; // Center the icon vertically
                width: 22px; // Set width to align with text
                height: 22px; // Set height to align with text
            }

            .ca-checkbox-text {
                font-size: 16px; // Font size for checkbox text
                color: #333; // Text color
                line-height: 1.2; // Line height for better readability
                font-family: Inter;
                font-weight: 600;
            }
        }
    }
}

// Responsive adjustments for smaller screens
@media (max-width: 480px) {
    .ca-checkbox-row {
        flex-wrap: nowrap; // Prevent wrapping on smaller screens
        justify-content: flex-start; // Align items to the left
        margin-bottom:30px;

        .ca-checkbox-group {
            flex: 0 0 33%; 
            display: flex;
            flex-direction: column; 
            align-items: flex-start; // Align items to the start of the column
            margin: 0 1px; // Reduced space between columns

            .ca-checkbox-item {
                display: flex;
                align-items: center; // Center items vertically
                padding: 5px; // Reduced padding for touch-friendly design
                margin-bottom: 1px; // Reduced margin between items for smaller screens
                .ca-checkbox-text{
                    font-size: 10px;
                }
                .elementor-icon-list-icon {
                    margin-right:5px;
                    width: 15px; // Set width to align with text
                    height: 15px; // Set height to align with text
                }
            }
        }
    }
}
@media(max-width:769px){
    .ca-checkbox-row {
        flex-wrap: nowrap; // Prevent wrapping on smaller screens
        justify-content: flex-start; // Align items to the left
        margin-bottom:30px;

        .ca-checkbox-group {
            &.first-column {
                flex: 0 0 25%; // First column takes 25%
            }
    
            &.other-columns {
                flex: 0 0 37.5%; // Each of the other columns takes 37.5%
            }
            display: flex;
            flex-direction: column; // Stack items vertically for 769px and below
            align-items: flex-start; // Align items to the start of the column
            margin: 0 1px; // Reduced space between columns

            .ca-checkbox-item {
                display: flex;
                align-items: center; // Center items vertically
                padding: 5px; // Reduced padding for touch-friendly design
                margin-bottom: 1px; // Reduced margin between items for smaller screens
                .ca-checkbox-text{
                    font-size: 12px;
                }
                .elementor-icon-list-icon {
                    margin-right:5px;
                    width: 15px; // Set width to align with text
                    height: 15px; // Set height to align with text
                }
            }
        }
    }
}

/* Two-column flex layout for form and suggestions */
.ca-content-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    gap: 40px;

    @media(max-width: 769px) {
        flex-direction: column;
        gap: 10px;
        /* Reduce gap further on small screens */
    }
}

/* Form column styling */
.ca-form-column {
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    gap: 10px;
    justify-content: center;

    @media(max-width: 480px) {
        flex-basis: 100%;
        /* Full width on mobile */
    }
}

.ca-label {
    font-size: 18px;
    color: #333;
    font-weight: 600;
}
.ca-input::placeholder{
    @media(max-width:480px){
        font-size:13px;
    }
}

.ca-input,
.ca-textarea {
    padding: 10px;
    font-size: 14px;
    //border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #b0b0b0;
    border-radius: 10px;
    margin-top: -6px;
}


.ca-textarea {
    resize: vertical;
    height: 10em;
}

/* Button styling */
.ca-button {
    background-color: rgba(63, 119, 248, 1);
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    align-self: flex-start;
}

.ca-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.ca-button:hover {
    background-color: #45a049;
}

/* Suggestion box styling */
.ca-suggestion-box {
    flex-basis: 60%;
    background: rgba(143, 191, 250, 0.15);

    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ccc;
    height: auto;
    min-height: 320px;

    @media(max-width: 769px) {
        flex-basis: 100%;
        position:relative;
        bottom:50px;
    }
}

.ca-suggestion-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
    font-weight: 500;
    font-family: Bricolage Grotesque;
}

.ca-suggestions {
    background-color: #fff;
    padding: 5px;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: border 0.3s ease;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 10px;

    @media(max-width: 380px) {
        flex-wrap: wrap;
    }
}

.ca-suggestion-button {
    font-family: Inter;
    background-color: transparent;
    padding: 10px 20px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 10px;


    @media(max-width: 380px) {
        width: 90%;
    }
}

.ca-suggestion-button:focus {
    outline: none;
}

.ca-suggestion-button:hover {
    background-color: rgba(219, 240, 255, 1);
    color: rgb(0, 150, 255);
}

.ca-suggestion-button.active {
    background-color: rgba(219, 240, 255, 1);
    color: rgb(0, 150, 255);

    @media(max-width: 380px) {
        width: 90%;
    }
}



.circle-question {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    background-color: #fff;
    color: #333;
    text-align: center;
    font-weight: bold;
    margin-left: 8px;
    font-size: 12px;
    border: 2px solid #ccc;
}




.ca-suggestion-list {
    list-style-type: none;
    padding: 0;
}

.ca-suggestion-item {
    font-family: Inter;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
    font-weight: 600;
    margin-bottom: 20px;
}

.ca-suggestion-item:hover {
    cursor: pointer;
    text-decoration: underline;
}

.ca-input,
.ca-textarea {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
    font-family: 'Inter';
}

.ca-dropdown-container {
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    max-height: 150px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.ca-dropdown-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ca-dropdown-item {
    padding: 8px 10px;
    border-bottom: 1px solid #eee;

    &:hover {
        background-color: #f0f0f0;
        cursor: pointer;
    }
}

/* Media Queries for larger screens */
@media (min-width: 769px) {
    .ca-content-row {
        flex-direction: row;
    }

    .ca-form-column {
        flex-basis: 40%;
    }

    .ca-suggestion-box {
        flex-basis: 60%;
    }
}
.Generate_button_large{
    display:block;
    @media(max-width:769px){
        display:none;
        opacity:0;
    }
}
.Generate_button{
    display:none;
     @media(max-width:769px){
        position:relative;
         display:block;
         bottom:35px;
         right:5px;
     }
 }

#tooltip {
    transform: translateX(-50%);
    left: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

#style_button {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

#buttonTip {
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}