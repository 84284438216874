@import "bulma/sass/utilities/all";
@import "bulma/sass/elements/notification";
@import "bulma/sass/elements/other";

.notification {
  z-index: 10000;
  
  @include from(1280px) {
    max-width: 60rem;
  }
}

.position-bottom {
  position: fixed !important;
  bottom: 25px;

  @include until(1021px) {
    bottom: 0;
    border-radius: 0;
  }
}
