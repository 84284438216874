@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/components/message";

.message.is-warning .message-body {
    border-color: #ffe08a !important;
}

.message.is-warning.no-website-message-box .message-body {
    border-color: #ffd500 !important;
}