@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

$input-placeholder-color: $grey-darker;

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import "bulma/sass/components/tabs";
@import "bulma/sass/components/panel";
@import "bulma/sass/form/all";
@import "bulma-switch";

@import "../../assets/bulma-overrides/bulmaOverrides";
@import "../../assets/custom-global-styles/customGlobablStyles";


$primary-color: #4caf50;
$hover-color: #45a049;
$input-border-color: #ccc;

.survey-option {
    cursor: pointer;
}