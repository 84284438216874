@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/components/card";

@import "../../assets/bulma-overrides/bulmaOverrides";

.contact-us-container {
  .contact-us-form {
    display: flex;
    flex-direction: column;

    .clear-file-input {
      color: $grey-darker;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
