@import "../assets/themes/mainTheme";
@import "../assets/fonts/customFonts";
@import "bulma/sass/utilities/all";
@import "bulma/sass/components/card";
@import "bulma/sass/helpers/typography";
@import "../assets/bulma-overrides/bulmaOverrides";

.error-page {
   font-family: $primary-font;
   background-image: linear-gradient(to bottom, #B5E9FF, #FFFFFF);
   height: 100vh;

   .error-icon {
      width: 50%;
      height: 4rem;
      margin-inline: auto;
      font-size: 4rem;
      margin-bottom: 4rem;
   }

   .card {
      position: absolute;
      width: 35%;
      padding-block: 1rem;
      box-shadow: 0 8px 17px rgba(0, 0, 0, 0.1607843137);
      border-radius: 1rem;
      top: 33%;
      right: 32%;

      @include until(1300px) {
         width: 60%;
         right: 20%;
      }

      @include until(769px) {
         width: 70%;
         right: 15%;
      }
   }

   p {
      line-height: 1.5;
   }
}


@media only screen and (max-width: 600px) {
   .error-page .card {
      position: static;
      padding-block: 1rem;
      box-shadow: 0 8px 17px rgba(0, 0, 0, 0.1607843137);
      border-radius: 1rem;
      right: 32%;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 200px;
   }
}