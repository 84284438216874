$page-sides-spacing: 8rem;
$page-sides-spacing-sm: 2rem;
$page-sides-spacing-xl: 18rem;

$first-breakpoint: 1650px;
$second-breakpoint: 1360px;
$third-breakpoint: 836px;
$fourth-breakpoint: 460px;

@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/components/card";

@import "../../assets/bulma-overrides/bulmaOverrides";

.signup-plan-selection-container * {
  font-family: "Bricolage Grotesque", Sans-serif;
}

.signup-plan-selection-container {
  display: block;
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to bottom, #B5E9FF, #FFFFFF);
  align-items: center;
  justify-content: center;

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      width: 72vw;

      .header-logo {
        background-color: #ffffff;
        border-radius: 50px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border: 3px solid #000000;
        width: auto;
        height: auto;
        box-shadow: 0px 7px 0px 0px #000000;
        zoom: .75;

        img {
          width: 50%;
          height: auto;
          margin-bottom: .4rem;
        }
      }

      .header-text {
        font-size: 2.5rem;
        text-shadow: 1.75px 0 #000000;
        letter-spacing:1px;
        font-weight: 500;
        color: $black;
      }
    }

    @media (max-width:600px) {
      .header{
        flex-direction:column;
        gap: 4em;
        text-align: center;
      }
      
    }
  }

  .plan-selection-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .offer-card {
      margin: 3rem 0 1rem 0 !important;
      width: 67rem;

      h2 {
        color: #7A63DF;
        font-family: "Bricolage Grotesque", Sans-serif;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.2px;
      }
    }

    @media (max-width:770px) {
      .offer-card{
        width: auto;
      }
      
    }

    .plan-cards-container {
      display: grid;
      width: 95%;
      // grid-template-columns: repeat(4, 1fr);
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      grid-row-gap: 2em;
      justify-content: center;
      // width: 68.75rem;
      max-width: 68.75rem;

      // @include until($first-breakpoint) {
      //   grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      //   justify-content: center;
      // }

      @include until($second-breakpoint) {
        row-gap: 1rem;
        column-gap: 1rem;
      }

      .plan-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $white;
        border: 3.25px solid #000;
        border-radius: 2rem;
        padding: 1.25rem;
        zoom: .85;

        // @include until($second-breakpoint) {
        //   height: 49rem;
        // }

        &:first-child {
          z-index: 1;
          @include until($second-breakpoint) {
            border: 4px solid #000;
            border-radius: 2rem;
          }
        }

        &:nth-child(2) {
          z-index: 2;
          margin-left: -1em;
          @include until($second-breakpoint) {
            border: 4px solid #000;
            border-radius: 2rem;
          }
        }

        &:nth-child(3) {
          z-index: 3;
          margin-left: -1em;
          @include until($second-breakpoint) {
            border: 4px solid #000;
            border-radius: 2rem;
          }
        }

        &:last-child {
          z-index: 5;
          margin-left: -1em;

          @include until($second-breakpoint) {
            border: 4px solid #000;
            border-radius: 2rem;
          }
        }

        hr {
          margin: 0.5rem auto;
          border: 0;
          border-top: 1px solid #ddddddaa;
          width: 80%;
        }

        .plan-details .plan-details--item {
          font-size: 1rem;
          padding-bottom: 5px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          text-wrap: nowrap;
        
          span.icon {
            margin-right: 2px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            max-width: 1.375rem;
            max-height: 1.375rem;
          }
        }

        .plan-name {
          font-size: 2rem;
          font-weight: 900;
          color: $black;
        }

        .plan-card:nth-of-type(3) .cancel-pricing {
          font-size: 0rem!important;
      }

        .cancel-pricing{
          font-weight: 500;
          font-size: 2.375rem;
          color: $grey-dark;
          text-decoration: line-through;
        }

        .pricing {
          font-weight: 700;
          font-size: 3.375rem;
          color: $black;

          .pricing-suffix {
            margin-left: 0.5rem;
            font-size: 1.125rem;
            font-weight: 400;
            color: $black;
          }
        }

        .plan-purchase-button {
          background-color: #ffffff;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 20px;
          border: 1px solid #000000;
          width: 75%;
          height: auto;
          box-shadow: 1px 2px 0px 0px #000000;
          margin: auto;
          margin-top: 1rem;
          cursor: pointer;
          transition: all 0.3s ease;

          &:disabled {
            filter: grayscale(0.8);
            cursor: not-allowed;
          }

          &:hover {
            transform: translate(-0.25rem, -0.25rem);
            background: #fac44b !important;
            box-shadow: 0.25rem 0.25rem #000000;
          }
        }

        &.popular {
          position: relative;
          border-radius: 2rem;
          background-color: #DAFFB5;
          transform: scale(1.075);
          box-shadow: 10px 10px 0px 0px #000000;

          @include until($second-breakpoint) {
            transform: scale(1);
          }

          .price {
            color: $black;
          }

          .plan-details .plan-details--item span.icon svg {
            ellipse {
              fill: #2dce89;
            }
    
            path {
              fill: #fff;
            }
          }

          .plan-purchase-button {
            background: #F2CE40;

            &:hover {
              transform: translate(0.2rem, 0.2rem);
              background-color: #ffffff !important;
              color: #000000 !important;
              border: 1px solid #000000;
              box-shadow: 0.25rem 0.25rem #00000000;
            }
          }
        }
      }
    }

    .feature-list-container {
      display: flex;
      flex-direction: column;

      .hands-free-message-img {
        width: 17rem;
        height: auto;
      }

      .pfp-list-img {
        width: 17rem;
        height: auto;
      }
    }
  }

  .signup-plan-selection--notification-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }

  @media screen and (max-height: 900px) {
    zoom: 0.9;
  }

  @media screen and (max-height: 800px) {
    zoom: 0.8;
  }
}

.icon-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}
