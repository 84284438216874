@import "../../assets/fonts/customFonts";

.main-nav {
    z-index: 999999 !important;
    padding-top: 30px;
}


.nav-container {
    display: flex;
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    padding: 15px;
    padding-left: 25px;
    padding-right: 20px;
    border-radius: 200px;
    border: 2px solid #000000;
    box-shadow: 5px 10px 1px 0px #000000;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: flex;
    align-items: flex-start;
}

.nav-group ul {
    display: flex;
    column-gap: 30px;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.nav-links,
.header-cta {
    font-family: $inter-font;
    font-size: 18px;
    font-weight: 500;
    color: black;
    text-decoration: none;
}

.header-cta {
    background: #fac44b !important;
    transition: 0.2s;
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px !important;
    border: 1px solid #000000;
    box-shadow: 0.25rem 0.25rem #000000;
}

.header-cta:hover {
    transform: translate(0.2rem, 0.2rem);
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #000000;
    box-shadow: 0.25rem 0.25rem #00000000;
}

.res-nav {
    display: none;
    height: 30px;
    width: 30px;
    align-content: space-around;
    justify-content: space-around;
    flex-direction: column;
    background-color: #ffffff;
}

.res-nav span {
    height: 0.2rem;
    width: 100%;
    background-color: rgb(0, 0, 0);
}

@media only screen and (max-width: 1024px) {
    .main-nav {
        padding-left: 20px;
        padding-right: 20px;
    }

    .logo {
        width: 120px;
    }

    .nav-container {
        padding: 10px 20px;
    }

}

// navbar do not overflow
@media only screen and (max-width:1118px) and (min-width:975px) {
    .nav-container {
        width: auto;
    }
}

@media only screen and (max-width: 974px) {
    .res-nav {
        display: flex;
        height: 30px;
        width: 30px;
        justify-content: space-around;
        background-color: #ffffff;

    }

    .nav-container {
        min-width: 90% !important;
        max-width: 90% !important;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-container.open {
        border: 2px solid #000000;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 5px 10px 1px 0px #00000000;
        border-bottom: 0px solid #00000000;
    }


    .nav-group ul {
        display: none;
    }

    .last {
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .nav-group ul.open {
        position: absolute;
        left: 0px;
        right: 0;
        border-radius: 0px 0px 10px 10px;
        border: 0px solid #000000;
        border-top: 0px solid #00000000;
        top: 50px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        column-gap: 30px;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 10px;
        position: absolute;
        background-color: #ffffff;
        padding: 21px;
        padding-bottom: 10px;
        box-shadow: 2px 2px 0px 0px #000000, -2px 2px 0px 0px #000000;


    }

    .nav-links,
    .header-cta {
        font-family: $inter-font;
        font-size: 20px;
        font-weight: 500;
    }


}