@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

.w-100 {
  width: 100%;
}

.tooltip-i {
  position: relative;
  display: inline-block;
  background-color: $black;
  border-radius: 100%;
  padding: 2px;
  width: 17px;
  height: 17px;

  &::after {
    content: "?";
    position: absolute;
    color: $white;
    font: 700 0.8rem $bricolage-font;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.font-primary {
  font-family: $bricolage-font;
}

.font-secondary {
  font-family: $inter-font;
}
