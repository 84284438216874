@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/elements/button";

.button.is-small {
  border-radius: 6px !important;
}

.transparent-button {
  border: none;
  background: transparent;
  cursor: pointer;
}