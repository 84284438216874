@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

// bulma overrides
$card-content-padding: 4rem;
$input-shadow: unset;
$input-border-color: $grey-dark;
$input-color: $grey-darker;
$input-placeholder-color: $grey-darker;

@import "bulma/sass/utilities/all";
@import "bulma/sass/helpers/all";
@import "bulma/sass/elements/button";
@import "bulma/sass/components/card";
@import "bulma/sass/form/all";

.auth-container {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, #B5E9FF, #FFFFFF);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.main-navbar{
  padding-top: 30px;

  .navbar-container{
      display: flex;
      align-items: center;
      width: auto !important;
      background-color: #ffffff;
      padding: 15px;
      padding-left: 25px;
      padding-right: 20px;
      border-radius: 200px;
      border: 2px solid #000000;
      box-shadow: 5px 10px 1px 0px #000000;


      .appsumo-logo{
        height: 30px;
      }

      @media (max-width:412px) {
        .appsumo-logo{
          height: 15px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .main-nav{
      padding-left: 20px;
      padding-right: 20px;

      .nav-container{
          padding: 10px 20px;
      }
  }
}


.signup-container {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .heading-text {
    font-family: $secondary-font;
    font-size: 3rem;
    line-height: 1.2em;
    font-weight: 700;
    color: $black;
    text-align: center;

    @include until($tablet) {
      font-size: 2rem;
    }

    @media screen and (max-width: 1560px) {
      font-size: 2.5rem;
    }
  }

  .epilogue {
    font-family: $epilogue-font;
  }

  .bricolage {
    font-family: $bricolage-font;
  }

  .subtitle-text {
    font-size: 1.5rem;
    color: $black;
    text-align: center;

    @media screen and (max-width: 1560px) {
      font-size: 1.25rem;
    }
  }

  .signup-card {
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 8px 17px #00000029;

    .card-content {
      padding: 4rem;

      @include until($tablet) {
        padding: 3rem !important;
      }

      .content .signup-btn-border {
        font-family: "Inter", Sans-serif;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 15px 50px 15px 60px;
        background-color: #ffffff !important;
        color: #000000 !important;
        border: 1px solid #000000;
        border-radius: 09px !important;
        transition: 0.2s;
      }

      .content .signup-btn-border:hover {
        transform: translate(-0.25rem, -0.25rem);
        background: #fac44b !important;
        box-shadow: 0.25rem 0.25rem #000000;
      }
      .content .signup-In-btn-border {
        font-family: "Inter", Sans-serif;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 15px 50px 15px 60px;
        transform: translate(-0.25rem, -0.25rem);
        background: #fac44b !important;
        box-shadow: 0.25rem 0.25rem #000000;
        color: #000000 !important;
        border: 1px solid #000000;
        border-radius: 09px !important;
        transition: 0.2s;
      }

      .content .divider-wrapper {
        display: flex;
        flex-direction: row;
        text-transform: uppercase;
        border: none;
        font-weight: 600;
        margin: 0;
        align-items: center;
        justify-content: center;
        vertical-align: baseline;

        .divider {
          text-align: center;
          flex: .2 0 auto;
          margin: 0;
        }
      }

      .content .connect-account {
        display: flex;
        width: 100%;
        justify-content: center;

        .google-option {
          font-weight: 600;
          background-color: #ffffff;
          border: 1px solid black;
          width: 300px;
          border-radius: 9px;
          height: 40px;
          padding: 7px;
        }

        .google-option:hover {
          cursor: pointer;
          box-shadow: inset 0 0 0 150px #0000001a
        }

      }
    }
  }
 
  /*@media screen and (max-width: 1300px) {
    width: 40%;
    right: 30%;
  }

  @media screen and (max-width: 1160px) {
    width: 50%;
    right: 25%;
  }

  @media screen and (max-width: 925px) {
    width: 60%;
    right: 20%;
  }

  @media screen and (max-width: 770px) {
    width: 80%;
    right: 10%;
  }

  @media screen and (max-width: 580px) {
    width: 90%;
    right: 5%;
  }

  @media screen and (max-width: 512px) {
    zoom: 0.8;
  }

  @media screen and (max-height: 900px) {
    zoom: 0.75;
  }

  @media screen and (max-height: 680px) {
    zoom: 0.7;
  }
  */

  

}


@media only screen and (max-width: 600px) {
  .signup-container{
    padding-top: 60px !important;
    width: 90%!important;
    margin-left: auto!important;
    margin-right: auto!important;
}

.signup-btn-border {
  padding: 15px 20px 15px 20px!important;
}
}


.show-error-message-below {
  position: relative !important;

  &::after {
    content: attr(data-err-msg);
    position: absolute;
    bottom: -25px;
    left: 0;
    color: $danger;
    font-size: .85rem;
  }
}

.show-error-message-above {
  position: relative !important;

  &::after {
    content: attr(data-err-msg);
    position: absolute;
    bottom: 3.5rem;
    left: 0;
    color: #e12047;
    font-size: 1rem;
    width: 100%;
    text-align: center;
  }
 
}
