.tutorial-container {
    text-align: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 800px;
    height: 100%;
    width: 100%;

    .video-grid {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
        row-gap: 3rem;

        .video-item {
            text-align: center;
            cursor: pointer;

            img {
                width: 100%;
                border-radius: 4px;
            }

        }
    }
}

.iframe-container {

    iframe {
        width: 70vw;
        height: 75vh;
    }
}

@media screen and (max-width: 1400px) {
    .iframe-container iframe {
        width: 80vw;
        height: 65vh;
    }
}

@media screen and (max-width: 1000px) {
    .iframe-container iframe {
        width: 90vw;
        height: 50vh;
    }
}