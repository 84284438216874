@import "../../assets/themes/mainTheme";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/components/menu";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/helpers/spacing";
@import "bulma/sass/components/dropdown";

@import "../../assets/bulma-overrides/bulmaOverrides";
@import "../../assets/custom-global-styles/customGlobablStyles";

$sidebar-edge-spacing: .5rem;
$sidebar-top-extra: 0.8rem;
$sidebar-header-height: 3.75rem;
$sidebar-footer-height: 2.5rem;

.new-sidebar * {
  font-family: "Bricolage Grotesque", Sans-serif !important;
}

.new-sidebar {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  width: 18rem;
  height: 100%;
  background-color: $white;
  box-shadow: -12px 0px 46px #0000000F;
  overflow: hidden !important;
  position: fixed;
  z-index: 6; // sidebar should not overlap with subscription page
  transition: ease-in-out 100ms;
  zoom: 100%;

  section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    /*Right pointing*/
    .triangle-btn {
      position: fixed;
      top: 1rem;
      left: 17rem;
      width: 1rem;
      height: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      overflow: hidden;
      z-index: 1;
      cursor: pointer;
    }

    .triangle-btn:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      margin-top: -500px;
      border-top: 500px solid transparent;
      border-bottom: 500px solid transparent;
      border-right: 500px solid #ddd;
      ;
    }

    div ul li ul {
      margin-top: 0px !important;
    }

    .link-text, .articles-li-text {

      &:hover {
        color: $primary;
      }
    }
  }

  &.collapsed {
    width: 5.5rem;
    transition: ease-in-out 100ms;

    section {

      .arrow {
        display: none;
        position: absolute;
        background-color: inherit;
        transform: translateY(-50%) rotate(134deg);
        z-index: 4;
        top: 50%;
      }

      .logo-container {
        padding: 0;
        position: relative;
        display: flex;
        justify-content: center;
        background-color: $white;
        cursor: pointer;

        .hamburger {
          display: none;
        }

        .abun-logo {
          width: 3rem;
          height: auto;
          cursor: pointer;
          display: block;
        }

        /*Right pointing*/
        .triangle-btn {
          position: fixed;
          top: 1rem;
          left: 5.5rem;
          width: 0;
          height: 0;
          padding-top: 1rem;
          padding-bottom: 1rem;
          padding-left: 1rem;
          overflow: hidden;
          z-index: 1;
          cursor: pointer;
        }

        .triangle-btn:after {
          margin-left: -500px;
          border-left: 500px solid #ddd;
        }
      }

      .link-text {
        display: none;
      }

      .category-menu, .subcategory-menu {
        position: relative;

        &:hover {

          .link-text {
            display: inline;
            position: fixed;
            width: max-content;
            left: 4.5rem;
            padding: 0.5rem;
            background-color: $white;
            border-radius: 0.25rem;
            z-index: 5;
            border: none;
            box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1), 0 0px 0 1px rgba(0, 0, 0, 0.02);
          }
        }
      }

      .dropdown-menu {
        position: fixed;
        top: 4rem;
        left: 3.5rem;
        width: 10rem;
      }

      .subcategory-menu {
        position: relative;

        ul {
          display: none;
        }

        &:hover {
          background-color: #f2f6fc;

          ul {
            display: block;
            position: fixed;
            width: max-content;
            left: 4.5rem;
            padding: 0.5rem;
            background-color: $white;
            border-radius: 0.25rem;
            z-index: 5;
            border: none;
            box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1), 0 0px 0 1px rgba(0, 0, 0, 0.02);

            .link-text, .articles-li-text {
              display: inline;
            }

            .arrow {
              display: block;
              left: -0.4rem;
              width: 1rem;
              height: 1rem;
            }
          }
        }

        &.articles:hover ul {
          top: 13.5rem;
        }

        &.keywords:hover ul {
          top: 16.5rem;
        }
      }
    }
  }

  @include until(1100px) {
    width: 15.5rem;
  }

  @include until($tablet) {
    width: 100%;
    height: 5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
    justify-content: unset;
  }

  &.full-height {
    height: 100% !important;
  }

  .mobile-extra {
    padding-left: $sidebar-edge-spacing;
    display: none;

    .mobile-logo {
      width: auto;
      height: 3em;
    }

    .hamburger {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    @include until($tablet) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .sidebar-header {
    position: relative;
    min-width: 0rem;
    max-width: 100%;
    height: $sidebar-header-height;
    background-color: $white;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-container {
      position: relative;
      width: 100%;
      height: auto;
      min-height: 3.75rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include until($tablet) {
        display: none;
      }

      .hamburger {
        width: 40%;
        height: auto;
      }

      img {
        width: 6rem;
        height: auto;
      }
    }

    ul {
      width: 90%;
      margin: 0 !important;
      margin-top: .25rem !important;
    }

  }

  .sidebar-items {
    position: relative;
    min-width: 0rem;
    max-width: 18rem;
    height: calc(100% - $sidebar-header-height - $sidebar-footer-height);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // show scrollbar only on hover
    &:hover {
      transition: ease-in-out 250ms;
      scrollbar-width: thin;
      scrollbar-color: $grey $white;
    }
  }

  .sidebar-footer {
    // should be fixed at the bottom of the sidebar
    position: relative;
    width: 18rem;
    height: $sidebar-footer-height;
    background-color: white;
    margin: 0 !important;
    z-index: 3;

    border-top: 1px solid $grey;

    li,
    a {
      margin: 0 !important;
    }

    // @include until($tablet) {
    //   display: none;
    // }
  }

  section ul {
    padding-right: 0px;
    margin-right: 0px;
  }

  hr {
    margin: 0 $sidebar-edge-spacing;
    background-color: $grey;
    height: 1px;
  }

  a.settings {
    display: block;
    text-align: left;
  }


  .align-image-text-vertically {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  
  .sidebar-user-website-list-item {
    &:hover {
      //background-color: $light !important;
      cursor: pointer !important;
    }
  }
  
  .sidebar-connect-website-list-item {
    background-color: $success;
    color: $white !important;
  
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }
  
    &:hover {
      background-color: $success !important;
    }
  }
  
  .sidebar-website-list-logo {
    vertical-align: middle;
  }
  
  .category-menu a {
    display: flex !important;
    align-items: center !important;
  
    &:hover,
    &.is-active {
      background-color: #fff !important;
      color: $primary !important;
  
      svg #hover {
        fill: #8fbffa !important;
      }
    }
  
    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  
  .subcategory-menu {
    a {
      display: flex !important;
      align-items: center !important;
  
      &:hover,
      &.is-active {
        background-color: #fff !important;
        color: $primary !important;
  
        svg {
          fill: #8fbffa !important;
        }
      }
  
      svg {
        width: 1.4rem;
        height: 1.4rem;
        fill: #fff !important;
      }
    }
  
    ul li a {
  
      &:hover,
      &.is-active {
        background-color: #fff !important;
        color: $primary !important;
      }
  
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  
      span {
        font-size: .9375rem;
      }
  
      span.tag {
        font-size: 0.75rem;
        margin-left: 0rem;
      }
    }
  
    a.coming-soon {
      cursor: default !important;
      pointer-events: none !important;
  
      &:hover {
        background: transparent !important;
        color: $black !important;
      }
    }
  
    a.upgrade {
      word-wrap: break-word;
    }
  }
  
  .category-menu.indexation {
    .category {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  
    a.upgrade {
      word-wrap: break-word;
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
    }
  
    span span {
      display: none;
    }
  }
  
  .deals-card {
    background: #E2F1FF;
    border-radius: 0.75rem;
    padding: 1rem;
    margin: 1rem;
    position: relative;
    min-height: 6.25rem;
    max-height: 6.25rem;
    cursor: pointer;
  
    .content {
      color: #3F77F8;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
  
      h3.title {
        color: #3F77F8 !important;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.1rem;
        margin: 0;
      }
  
      .subtitle {
        color: #3F77F8 !important;
        font-size: .8125rem;
        font-weight: 400;
        margin: 0 !important;
        line-height: 1.1rem;
      }
  
      .code {
        color: #3F77F8 !important;
        font-size: .8125rem;
        font-weight: 400;
        line-height: 1.1rem;
      }
    }
  }
  
  .menu-list {
    margin-left: .5rem;
  
    .dropdown {
      width: 100% !important;
  
      .dropdown-trigger {
        button {
          width: 80%;
          margin: auto;
          text-align: left;
          padding: 0.325rem;
          border: 1px solid #B3B3B3;
          background-color: transparent;
          color: $black;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          &:hover {
            background-color: $light;
          }
  
          img {
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 50%;
          }
  
          // down arrow
          svg {
            width: .875rem;
            height: .875rem;
            margin-right: 0.25rem;
          }
        }
      }
  
      .dropdown-menu {
        width: 100%;
  
        .dropdown-content {
          padding: 0;
          width: 80%;
          margin: auto;
          margin-top: 0rem;
        }
  
        .dropdown-item {
          padding: 0.5rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          color: $black;
          display: flex;
          align-items: center;
  
          &:hover {
            background-color: $light;
          }
  
          img {
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 50%;
          }
  
          .sidebar-add-website {
            width: 100%;
  
            &:hover {
              cursor: pointer;
  
            }
  
          }
  
        }
      }
    }
  }
  
  &.collapsed {
    .sidebar-header {
      min-width: 5.5rem !important;
      max-width: 5.5rem !important;
    }
  
    .sidebar-items {
      min-width: 5.5rem !important;
      max-width: 5.5rem !important;
      overflow-x: hidden;
    }
  
    .sidebar-footer {
      width: 5.5rem !important;
    }
  
    .menu-list {
      margin-left: 0rem;
    }
  
    .uncollapsed-tag {
      display: none;
    }
  
    .deals-card {
      display: none;
    }
  
    .menu-list .dropdown .dropdown-trigger button {
      width: 50% !important;
  
      svg {
        display: none;
      }
    }
  
    .category-menu a svg,
    .subcategory-menu a svg {
      margin: auto;
    }
  
    .category-menu.indexation {
      .category {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
  
      a.upgrade {
        word-wrap: break-word;
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
      }
    }
  
  
  }
  
  

  // .new-sidebar.collapsed section .category-menu:hover .link-text {
  //   left: 0rem;
  //   bottom: 2.5rem;
  // }

  .subcategory-product {
    .other-product-container {
      display: flex;
      align-items: center;
      cursor: pointer;
  
      svg {
        width: 1.4rem;
        height: 1.4rem;
        fill: #fff !important;
      }
  
      .dropdown-menu-btn {
        .other-product-text {
          color: #000;
        }
  
        &:hover {
          // This refers to .dropdown-menu-btn:hover
          .other-product-text {
            color: $primary;
          }
          // .arrow-icon{
          //   color:#000 !important;
          //   fill:none !important;
          // }
        }
      }
    }
  
    &:hover {
      // This refers to .other-product-container:hover svg
      svg {
        color: $primary;
        fill: none !important;
      }
    }
  }

  &.collapsed section .subcategory-product
  .other-product-container
    .dropdown-menu{
    position: fixed;
    top: 41rem;
    left: 5.5rem;
    width: 10rem;
  }

  &.collapsed section .subcategory-product {
    .other-product-container {
      .dropdown-trigger{
        display: none;
      }
  
      svg{
        flex: none;
        margin-left: 1.19rem;
        margin-top: 0.5rem;
      }
    }
  
    &:hover{
      background: none !important;
    }
  }


  .article-section{
    .article-dropdown{
      display: flex;
      align-items: center;
      padding: 0.5rem 0.85rem;
      cursor: pointer;
  
      .svg-article-icon {
        width: 1.4rem;
        height: 1.4rem;
        fill: #fff !important;
      }
    }
    &:hover {
      .article-text{
        color: $primary;
      }
  
      .svg-article-icon {
        fill: #8fbffa !important;
      }
    }
  
    // move ul border to right side
    ul{
      margin-left: 1.3em !important;
      padding-left: 0.5em !important;
    }
  }

  // collapsed sidebar article dropdown --start--
  &.collapsed section .article-section
  .article-dropdown{
    .svg-article-icon {
      margin: auto;
    }
  
    .article-text{
      display: none;
    }
    span{
      display: none;
    }
  }

  &.collapsed  section .sidebar-items .menu-list .article-section ul{
    border: none;
    position: fixed;
    width: max-content;
    top:9rem;
    left: 3.7rem;
    padding: 0.5rem;
    background-color: $white;
    border-radius: 0.25rem;
    z-index: 200;
    border: none;
    box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1), 0 0px 0 1px rgba(0, 0, 0, 0.02);
  
  
    .articles-li-text{
      margin: 0 !important;
    }
  }
  // collapsed sidebar article dropdown --end--
}

