@media (max-width: 450px) {
    .blog-finder-responsive-content {
        display: none; 
    }

    .blog-finder-upload-container {
        padding: 10px;
    }

    .blog-finder-upload-box {
        max-width: 240px; 
    }
}

.blog-finder-input-container{
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}