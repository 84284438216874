@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/elements/button";

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.button {
  border-radius: 7px;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.white-spinner {
  display: none;
  width: auto;
  height: 20px;
  vertical-align: middle;
  filter: invert(95%) sepia(96%) saturate(1%) hue-rotate(145deg) brightness(103%) contrast(100%);
  animation: spin 1500ms linear 0ms infinite;

  &.show {
    display: inline-block;
  }
}

.icon-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}
